import React, { useState } from "react"
import Layout from "../components/layout"
import PageHeader from "../components/header/page-header"
import { useStaticQuery, graphql, Link } from "gatsby"

import Image from "gatsby-image"
import SEO from "../components/seo"

const Cases = () => {
  const data = useStaticQuery(graphql`
    query Cases {
      bgImage: file(relativePath: { eq: "blog/bg-page-title.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      eduplusLogo: file(relativePath: { eq: "products/eduplusLogo.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            src
          }
        }
      }
      eduplusImage: file(relativePath: { eq: "products/eduplusImage.jpg" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      revision_live_logo: file(
        relativePath: { eq: "products/revision_live_logo.png" }
      ) {
        childImageSharp {
          fluid(quality: 100) {
            src
          }
        }
      }
      revision_live_image: file(
        relativePath: { eq: "products/revision_live_image.jpg" }
      ) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      yelaLogo: file(relativePath: { eq: "products/yelaLogo.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            src
          }
        }
      }
      yelaImage: file(relativePath: { eq: "products/yelaImage.jpg" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      laura_logo: file(relativePath: { eq: "products/laura_logo.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            src
          }
        }
      }
      laura_image: file(relativePath: { eq: "products/laura_image.jpg" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const [filters, updateFilters] = useState(filter_default)

  const onClickFilter = key => {
    updateFilters(state => {
      return state.map(f => {
        if (f.key === key) {
          return {
            ...f,
            active: true,
          }
        } else {
          return {
            ...f,
            active: false,
          }
        }
      })
    })
  }

  const headerBg = data.bgImage.childImageSharp.fluid

  const projects_data = [
    {
      category: "Education",
      filter: ["education"],
      featureImage: data.eduplusImage.childImageSharp.fluid,
      projectTitle: "Empowered Your Education",
      clientImage: data.eduplusLogo.childImageSharp.fluid.src,
      bgColor: "#71cbcc",
      to: "/products/eduplus-lms",
    },
    // {
    //   category: 'Education',
    //   filter: ['education'],
    //   projectTitle: 'Learn . Prepare . Apply',
    //   featureImage: data.revision_live_image.childImageSharp.fluid,
    //   clientImage: data.revision_live_logo.childImageSharp.fluid.src,
    //   bgColor: '#46e88b',
    //   to: '/products/revision-live'
    // },
    {
      category: "Enterprise",
      filter: ["Enterprise"],
      projectTitle: "Seamless processes, better business",
      featureImage: data.yelaImage.childImageSharp.fluid,
      clientImage: data.yelaLogo.childImageSharp.fluid.src,
      bgColor: "#ff2c9c",
      to: "/products/yela-erp",
    },
    // {
    //   category: 'Hostpitality',
    //   filter: ['hostpitality'],
    //   featureImage: data.laura_image.childImageSharp.fluid,
    //   projectTitle: 'Technologies that enrich experiences',
    //   clientImage: data.laura_logo.childImageSharp.fluid.src,
    //   bgColor: '#191937',
    //   to: '/products/laura-hms'
    // }
  ]
  return (
    <Layout bgColor="#f4f4f9">
      <SEO
        title="Products | Tested and Trusted systems to your business"
        description="We have developed and designed IT solutions as products specific to particular industries. These tried and tested systems can guarantee your business added efficiency and growth."
      />
      <PageHeader
        background={headerBg}
        title="Products | Tested and Trusted systems to your business"
        subtitle="We have developed and designed IT solutions as products specific to particular industries. These tried and tested systems can guarantee your business added efficiency and growth."
        thisPage="Products"
        className="pink"
      />
      <div className="container-fluid pt-5 mt-5 pb-5">
        <div className="col-md-9 mx-auto">
          {/* <div className="projects-filter projects-filter-center pb-3">
            <ul>
              {filters.map((filter, index) => {
                return (
                  <li key={index}>
                    <a
                      role="button"
                      href="/#"
                      className={filter.active ? "active" : ""}
                      key={filter.key}
                      onClick={() => onClickFilter(filter.key)}
                    >
                      {filter.name}
                    </a>
                  </li>
                )
              })}
            </ul>
          </div> */}
          <div className="projects-grid mb-5">
            <div className="row">
              {projects_data.map((project, index) => {
                return (
                  <Project
                    key={index}
                    category={project.category}
                    filter={project.filter}
                    bgColor={project.bgColor}
                    featureImage={project.featureImage}
                    clientImage={project.clientImage}
                    projectTitle={project.projectTitle}
                    to={project.to}
                  />
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

const filter_default = [
  {
    key: "all",
    name: "All",
    active: true,
  },
  {
    key: "enterprise",
    name: "Enterprise",
    active: false,
  },
  {
    key: "education",
    name: "Education",
    active: false,
  },
  {
    key: "hospitality",
    name: "Hospitality",
    active: false,
  },
]

const Project = ({
  category,
  filter,
  featureImage,
  projectTitle,
  clientImage,
  bgColor,
  to,
}) => {
  return (
    <div className={`col-md-6 m-0 p-0  ${filter[0]}`}>
      <div className="project" style={{ height: "100%" }}>
        <div className="project-inner">
          <Link
            style={{ backgroundColor: bgColor }}
            className="mask"
            to={to}
          ></Link>
          <figure className="project-thumbnail">
            <div className="project-data">
              <div className="project-category">
                <a href="/#">{category}</a>
              </div>
              <Link className="featured-image" to={to}>
                <Image fluid={featureImage} alt="explorelogy project image" />
              </Link>
            </div>
          </figure>
          <div className="project-info mx-auto">
            <div className="project-info-inner">
              <Link to={to}>
                <h2 className="project-title">{projectTitle}</h2>
                <div className="project-client">
                  <img src={clientImage} alt="explorelogy project logo" />
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Cases
